import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { EntityModel, LinkedModel, FieldsModel, FiltersModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormModalComponent } from 'src/app/shared/form-modal/form-modal.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-entity-detail',
    templateUrl: './entity-detail.component.html',
    styleUrls: ['./entity-detail.component.css'],
    standalone: false
})
export class EntityDetailComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private storage: StorageService,
    private notifier: NotifierService,
    public dialog: MatDialog
  ) { }

  private destroy$ = new Subject<void>();
  routeData = this.route.data['_value'];
  id: number;
  sourceName: string;
  page: EntityModel;
  fields_: FieldsModel;
  fieldsList: string[];
  visibleFields: FieldsModel[];
  visibleFieldsKV: FieldsModel[] = [];
  visibleFieldsList: string[] = [];
  fieldsGroups: string[];
  options: {};
  linked: LinkedModel[];
  filters: FiltersModel[];
  data: any = [];
  formSelectList: string[];
  dynamicLinked = [];
  dynamicAddLinked = [];
  isEditMode: boolean;
  dynamicData = [];
  formMode: ('insert' | 'update') = null;
  allFieldsList: string[];
  linkedAddShow: boolean[] = [];
  linkedAddEntries = [];
  linkedAddForm = [];
  role: string = null;
  filter_input: [] = []

  ngOnInit() {
    this.role = this.storage.getRole();
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.id = +params['id'];
        // Nel caso in cui il parametro della url è non numerico intero
        if (typeof this.id !== "number" || isNaN(this.id) || this.id <= 0 || Math.trunc(this.id) != this.id) {
          // Reindirizzamento alla pagina 404
          this.router.navigate(['/404']);
        }
        // parametro source ricevuto in input da app-routing.module.ts
        this.sourceName = this.routeData['source'];
        this.page = new EntityModel(this.sourceName);
        // recupera la lista dei campi (tutti)
        this.fieldsList = this.page.getFieldsList();
        this.visibleFields = this.page.getVisibleFields();
        this.visibleFieldsKV = this.page.getVisibleFieldsKV();
        this.visibleFieldsList = this.page.getVisibleFieldsList();
        this.allFieldsList = this.page.getAllFieldsList();
        this.fieldsGroups = this.page.getFieldsGroups();
        this.options = this.page.getOptions();
        this.linked = this.page.getLinked();
        this.formSelectList = this.page.getFormSelectList();
        // applica il filtro per recuperare solo l'oggetto della pagina
        this.filters = [{ field: "id", operator: "=", value: this.id }];
        // carica i dati
        this.loadData();
      });
  }

  loadData() {
    // scarica i dati dell'entità dall'api
    this.api.select(typeof this.page.model, this.sourceName, this.allFieldsList, this.filters, [], {})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.data = data[0];
          if(this.data) {
            this.loadSelect();
            this.loadLinked();
          } else {
            this.noData();
          }
        }
      );
  }

  loadSelect() {
      // Recupera la lista dei campi che hanno una form select
      this.formSelectList.forEach(fieldName => {
        this.dynamicData[fieldName] = {
          "form": this.page.getFormSelectByFieldName(fieldName),
          "data": []
        };
      });

    // scarica eventuali dati per le form select
    this.formSelectList.forEach(fieldName => {
      let options: {} = {};
      if (this.dynamicData[fieldName].form.select.distinct) {
        options = {
          'distinct': true
        };
      }

      let selectList = [];
      // id della select
      selectList.push(this.dynamicData[fieldName].form.select.id);
      // label della select
      selectList.push(this.dynamicData[fieldName].form.select.label);
      // eventuale chiave per un filtro dipendente da altra select
      if (this.dynamicData[fieldName].form.select.upSelectFilter) {
        selectList.push(this.dynamicData[fieldName].form.select.upSelectFilter.filterKey);
      }

      this.api.select(
        typeof {},
        this.dynamicData[fieldName].form.select.sourceName,
        selectList,
        this.dynamicData[fieldName].form.select.filters,
        [],
        options)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.dynamicData[fieldName]['data'] = data;
          }
        );
    });
  }

  loadLinked() {
    // scarica eventuali dati per le entità collegate
    this.linked?.forEach(obj => {
      const fields: string[] = obj.fields.map(field => { return field.name; });
      const firstVisibleField: string = obj.fields.filter(field => field.format!='hidden').map(field => { return field.name; })[0];
      // recupera i filtri dal modello
      const filters: FiltersModel[] = obj.filters.slice();
      // aggiunge il filtro dell'entità aperta
      filters.push(
        { field: obj.idJoinLinked, operator: "=", value: this.data[obj.idJoinMain], }
      );
      // recupera gli oggetti associati
      this.api.select(typeof {}, obj.sourceName, fields, filters, [{ field: firstVisibleField, direction: 'asc' }], {})
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.dynamicLinked[obj.sourceName] = data;
            // prepara la lista dei linked già associati
          }
        );
      // in caso di relazione "n a m"
      if (obj.select) {
        // recupera tutti i possibili valori dell'entità collegata per aggiungerle
        let selectFields = [obj.select.id, obj.select.label];
        this.api.select(typeof {},
          obj.select.sourceName,
          selectFields,
          obj.select.filters, [{ field: obj.select.label, direction: 'asc' }], {})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              data => {
                this.dynamicData[obj.select.sourceName] = {
                  "form": null,
                  "data": data
                };
              }
            );
      }
      // in caso di relazioni "1 a n"
      if (obj.addFields) {
        obj.addFields.forEach(f => {
          if (f.form?.select) {
            // recupera tutti i possibili valori dell'entità collegata per aggiungerle
            this.api.select(typeof {},
              f.form.select.sourceName,
              [f.form.select.id, f.form.select.label],
              f.form.select.filters, [{ field: f.form.select.label, direction: 'asc' }], {})
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  data => {
                    this.dynamicData[f.form.name] = {
                      "form": f.form,
                      "data": data
                    };
                    // this.filteredDynamicData[obj.select.sourceName]['data'] = data;
                  }
                );
          }
        });
      }
    });
  }

  handleActionsClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  getGroupFields(groupName: string) {
    return this.page.getGroupFields(groupName);
  }

  getPropertyList(arr: any[], property: string) {
    return arr.map(f => { return f[property]; });
  }

  getSortedActive(arr: any[]) {
    return arr
      .filter(f => { return f.format != 'hidden'; })
      .sort((a, b) => a.tableSort.toString().localeCompare(b.tableSort.toString(), 'it'));
  }

  getPropertyKV(arr: any[], property: string) {
    let output: FieldsModel[] = [];
    arr.forEach(field => {
      output[field[property]] = field;
    });
    return output;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isLinkedUsed(linkedObj: LinkedModel, id: number) {
    const relatedIdName = linkedObj.routerId;
    const sourceName = linkedObj.sourceName;
    const searchWhere = this.dynamicLinked[sourceName].map(f => f[relatedIdName]);
    return searchWhere.indexOf(id) > -1;
  }

  addLinked(linked: LinkedModel) {
    const sourceName = linked.sourceName;
    const entityField = linked.idJoinLinked;
    const entityValue = this.data[linked.idJoinMain];
    const relatedField = linked.routerId;
    const relatedSourceName = linked.select?.sourceName;
    const relatedValues = this.linkedAddEntries[relatedSourceName];

    relatedValues.forEach(relatedValue => {
      var fields = {};
      fields[entityField] = entityValue;
      fields[relatedField] = relatedValue;
      fields = <FieldsModel>fields;

      this.api.insert(null, sourceName, fields)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.loadData();
            this.linkedAddShow[relatedSourceName] = false;
            // svuota la lista dei selezionati - pulsante "Aggiungi"
            this.linkedAddEntries[relatedSourceName] = null;
            this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
          },
          error: (err) => {
            this.linkedAddShow[relatedSourceName] = false;
            if(err.status != 444) {
              // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
              console.log(err);
              this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persistee');
            }
          }
        });
    });
  }

  deleteLink(sourceName: string, id: number) {
    if (!id) {
      this.notifier.showError('Errore', 'Configurazione dell\'entità non corretta. Segnalare l\'errore a un amministratore');
      return;
    }
    if (confirm('Procedere con l\'eliminazione?')) {
      // const fields = {
      //   'active': 0
      // };
      const filters: FiltersModel[] = [{
        field: 'id',
        operator: '=',
        value: id
      }];
      this.api.delete(null, sourceName, /*fields,*/ filters)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.loadData();
            this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
          },
          error: (err) => {
            if(err.status != 444) {
              // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
              console.log(err);
              this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persistee');
            }
          }
        });
    }
  }

  addLinkedForm(linked: LinkedModel) {
    var error = false;
    var fields = {};
    var sourceName = linked.sourceName;
    var relatedSourceName = linked.sourceName;

    linked.addFields?.forEach(f => {
      // Check obbligatorietà
      if (f.form?.isRequired && !this.linkedAddForm[f.form?.name]?.toString().length) {
        error = true;
      }
      // Check lunghezza massima
      if (f.form?.maxLength && this.linkedAddForm[f.form?.name]?.toString().length > f.form?.maxLength) {
        error = true;
      }

      if (f.form?.name) {
        fields[f.form.name] = this.linkedAddForm[f.form.name];
      }
    });
    if (error) {
      this.notifier.showWarning('Attenzione', 'La form non è compilata correttamente');
      return false;
    }

    fields[linked.idJoinLinked] = this.data[linked.idJoinMain];
    fields = <FieldsModel>fields;

    this.api.insert(null, sourceName, fields)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.loadData();
          this.linkedAddShow[relatedSourceName] = false;
          this.linkedAddForm = [];
          this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
          return true;
        },
        error: (err) => {
          if(err.status != 444) {
            // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
            console.log(err);
            this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persistee');
          }
          return false;
        }
      });

    return false;
  }

  openDialog(_type: ('insert' | 'update'), obj?: typeof this.page.model): void {
    const dialogRef = this.dialog.open(FormModalComponent, {
      data: { model: this.page, formMode: _type, selObj: obj },
      panelClass: 'full-page-dialog'
    });

    // metodo invocato quando il dialog viene chiuso
    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.result == 'ok')
          this.loadData();
      });
  }

  noData() {
    this.notifier.showError('Errore', 'URL errata o dati non disponibili');
  }

  isLinkedVisible(link: LinkedModel): boolean {
    return link.showCondition == undefined || this.data[link.showCondition];
  }

  filterSelect(data: any, label: string, filter: string) {
    if(filter) {
      return data.filter((item: any) => item[label].toLowerCase().includes(filter.toLowerCase()));
    }
    return data;
  }

  toggleActive(setActive: boolean): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { 
            title: setActive ? 'Attivazione' : 'Disattivazione',
            message: setActive ? 
                'Procedere con l\'attivazione dell\'elemento?' : 
                'Procedere con la disattivazione dell\'elemento?'
        },
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result === true) {
            const fields = {
                'active': setActive ? 1 : 0
            };
            const filters: FiltersModel[] = [{
                field: 'id', 
                operator: '=', 
                value: this.id 
            }];

            this.api.update(null, this.sourceName, fields, filters)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (response) => {
                  this.loadData();
                  this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
                },
                error: (err) => {
                  if(err.status != 444) {
                    console.log(err);
                    this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persiste');
                  }
                }
              });
          }
        });
  }
}

@Component({
  selector: 'confirmation-dialog',
  template: `
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content>{{data.message}}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Annulla</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Conferma</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent, 
    MatDialogActions,
    MatDialogClose,
    MatButtonModule
  ]
})
export class ConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string}) {}
}