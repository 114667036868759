<mat-card style="max-height: 100%; overflow-y: auto; min-width: 360px;">
    <mat-card-title-group>
        <mat-card-title *ngIf="!loggedIn">Cambio password</mat-card-title>
        <mat-card-subtitle style="padding: 24px">
                Inserire le attuali credenziali per accedere al sistema<br />e successivamente una nuova password.
        </mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field appearance="outline" class="fill-width">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username" #username autocomplete="username" />
                    <mat-icon matSuffix *ngIf="f.username.errors==null" class="valid-icon">check</mat-icon>
                    <mat-error *ngIf="f.username.hasError('required')">Username obbligatoria</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" class="fill-width">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="password" />
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-icon matSuffix *ngIf="f.password.errors==null" class="valid-icon">check</mat-icon>
                    <mat-error *ngIf="f.password.hasError('required')">Password obbligatoria</mat-error>
                </mat-form-field>
            </p>
            <br />
            <p>
                <mat-form-field appearance="outline" class="fill-width">
                    <mat-label>Nuova Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPassword" autocomplete="new-password" (input)="onPasswordInput()" />
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-icon matSuffix *ngIf="f.newPassword.errors==null" class="valid-icon">check</mat-icon>
                    <mat-error *ngIf="f.newPassword.hasError('required')">Password obbligatoria</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" class="fill-width">
                    <mat-label>Conferma Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" autocomplete="new-password" (input)="onPasswordInput()" />
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-icon matSuffix *ngIf="f.confirmPassword.errors==null" class="valid-icon">check</mat-icon>
                    <mat-error *ngIf="f.confirmPassword.hasError('required')">Password obbligatoria</mat-error>
                    <mat-error *ngIf="f.confirmPassword.invalid && !f.confirmPassword.hasError('required')">Le password non coincidono</mat-error>
                </mat-form-field>
            </p>
            <p *ngIf="error" class="error">
                {{error}}
            </p>
            <div class="button">
                <button type="button" mat-button [routerLink]="['/login']" *ngIf="!loggedIn">Indietro</button>
                <button [disabled]="loading" type="submit" mat-button color="primary">Cambia Password</button>
            </div>
        </form>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
</mat-card>