import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, catchError, switchMap, throwError } from 'rxjs';

import { StorageService } from './storage.service';
import { AuthService } from './auth.services';
import { EntityModel } from '../models/entity.model';
import { NotifierService } from 'src/app/services/notifier.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private notifier: NotifierService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isRefreshToken = request.url.toString().indexOf('/api/refresh_token') > -1;
    const isInsert = request.url.toString().indexOf('/api/insert') > -1;
    const isUpseert = request.url.toString().indexOf('/api/upsert') > -1;
    // Aggiungi il token di autenticazione alle richieste
    const token = isRefreshToken ? this.storage.getRefreshToken() : this.storage.getToken();
    // Aggiungi l'intestazione Authorization se il token esiste
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    
    // Verifica se aggiungere "id_company" al body
    if (this.storage.isUserSysAdmin() && request.body && typeof request.body === 'object' && this.storage.getUserCompany()) {
      const modifiedBody = { ...request.body };
      
      if (modifiedBody['table']) {
        const table = new EntityModel(modifiedBody['table']);
        
        if (table.sourceName && table.hasCompanyFilter()) {

          if (isInsert || isUpseert) {
            if (typeof modifiedBody['fields']  === 'object') {
              modifiedBody['fields'].id_company = this.storage.getUserCompany();
            } else {
              // sto facendo una insert e non ho una lista di campi. sollevo errore
              return throwError(() => new Error('Non è possibile inserire un record senza specificare una lista di campi'));
            }
          } else {
            if (Array.isArray(modifiedBody['filters'])) {
              modifiedBody['filters'].push({ field: "id_company", operator: "=", value: this.storage.getUserCompany() });
            } else {
              modifiedBody['filters'] = [{ field: "id_company", operator: "=", value: this.storage.getUserCompany() }];
            }
          }
          

          request = request.clone({
            body: modifiedBody,
          });
        }
      }
    }

    // return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (isRefreshToken) {
          // l'errore è causato dal metodo refresh_token
          this.auth.logout();
          return throwError(() => new Error('RefreshToken scaduto'));
        }

        if (error.status === 401) {
          // Il token è scaduto, esegue il refresh token
          // Prima verifica se c'è un refreshToken
          const refreshToken = this.storage.getRefreshToken();
          if (!refreshToken) {
            // if(confirm('Vuoi uscire dall\'app?'))
            this.auth.logout();
            return throwError(() => error);
            // return EMPTY;
          }
          return this.auth.refreshToken().pipe(
            switchMap((response) => {
              // Aggiorna il token di accesso nel servizio di autenticazione
              this.storage.saveUser(response);
              const newToken = this.storage.getToken();
              // Riprova la richiesta originale con il nuovo token
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`
                }
              });
              return next.handle(request);
            })
          );
        }

        if (error.status === 425) {
          // Accesso con password temporanea
          this.auth.expiredPassword();
        }

        if (error.status === 444) {
          // Caso di eccezione gestita dal back-end, viene mostrato a video
          // this.notifier.showError('Errore', error.error['detail']);
          // TODO: gestire errore
          this.notifier.showError('Errore', error.error.detail);
          return throwError(() => error);
          return EMPTY;
        }

        // Altri errori, restituisci l'errore così com'è
        // return throwError(() => new Error(error.toString()));
        return throwError(() => error);
      })
    );
  }
}
