<div class="report-home-container">
    <span class="report-home-title">Seleziona il report che si desidera visualizzare</span>
    <small style="margin-bottom: 16px;" *ngIf="reports.length == 0">
        <p>Potresti non essere abilitato a visualizzato nessun report o
            il caricamento dei tuoi report potrebbe essere andato in errore.<br />
            Contatta gli amministratori per avere maggiori informazioni.</p>
    </small>
    <div class="report-home-grid">
        <div *ngFor="let report of reports" class="report-home-item" [routerLink]="report.path">
            <div class="report-icon"><mat-icon>{{report.icon}}</mat-icon></div>
            <div class="report-description">{{report.description}}</div>
        </div>
    </div> 
</div>