import { Component, ViewChild } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router, NavigationEnd, Route, NavigationStart } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService } from './services/api.service';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { CompanyModel, CompanyReportModel } from './models/db.model';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environment/environment';
import { ReportComponent } from './components/report/report.component';
import { RoleGuard } from './guards/role-guard.guard';

const DEFAULT_TITLE = 'Cooperative in Cloud';
const PRODUCTION = environment.production;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {
    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
    private destroy$ = new Subject<void>();
    siteFavicon: HTMLLinkElement = document.querySelector('#site_favicon');
    siteTitle: HTMLLinkElement = document.querySelector('#site_title');
    smallScreen: boolean = false;
    isUserSysAdmin: boolean = false;

    role: string = 'user';
    base_menu = [
        { 'type': 'item', 'routerLink': '/', 'icon': 'home', 'title': 'Home', 'role': 'user' },
        { 'type': 'item', 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': 'user' },
        { 'type': 'item', 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze', 'role': 'user', 'hideContract': true },
        { 'type': 'item', 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni', 'role': 'user' },
        { 'type': 'item', 'routerLink': '/upload', 'icon': 'cloud_upload', 'title': 'Upload', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/statistiche', 'icon': 'leaderboard', 'title': 'Statistiche', 'role': 'user' },
        {
            'type': 'dropdown', 'icon': 'dog', 'title': 'Anagrafiche', 'role': 'admin', 'items': [
                { 'type': 'item', 'routerLink': '/commesse', 'icon': 'business', 'title': 'Commesse', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/istituti', 'icon': 'school', 'title': 'Istituti', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/allievi', 'icon': 'child_care', 'title': 'Allievi', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/attivita', 'icon': 'edit_calendar', 'title': 'Attività', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/qualifiche', 'icon': 'edit_calendar', 'title': 'Qualifiche', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/anni', 'icon': 'refresh', 'title': 'Anni', 'role': 'admin' },
            ]
        },
        {
            'type': 'dropdown', 'icon': 'people_alt', 'title': 'Archivio', 'role': 'admin', 'items': [
                { 'type': 'item', 'routerLink': '/archivio/presenze', 'icon': 'people_alt', 'title': 'Presenze', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/archivio/assenze', 'icon': 'people_alt', 'title': 'Assenze', 'role': 'admin' },
            ]
        },
    ];
    menu = [];

    loggedIn: boolean = false;
    termsAndConditions: boolean = false;
    admin: boolean = false;
    principal: boolean = false;
    opened: boolean;
    events: string[] = [];
    username: string = '';
    mobile: boolean = false;
    contract: string;
    companies: [] = [];
    selectedCompany: number = 0;
    companyInfo: CompanyModel;
    menuImage: string;
    headerImage: string;
    companyReports: CompanyReportModel[] = [];

    constructor(
        private storage: StorageService,
        private api: ApiService,
        private router: Router,
        private titleService: Title,
        private meta: Meta,
    ) {
        this.initializeApp();
    }

    private async initializeApp() {
        this.checkScreenSize();

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
            )
            .subscribe((ev) => {
                if (this.smallScreen) this.sidenav?.close();
                this.mobile = this.storage.getMobile() == 'true' ? true : false;
                this.handleUserAuthentication();
                this.checkScreenSize();
            });

        // Solo la prima volta, aggiorno le voci di menu dei report
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1) // Prende solo il primo evento NavigationEnd
            )
            .subscribe((ev) => {
                this.menu = [...this.base_menu];
                this.loadCompanyInfoFromStorage();
            });

        if (this.storage.getToken()) {
            await this.api.saveCompanyInfo();
            this.loadCompanyInfoFromStorage();
        }
    }

    private handleUserAuthentication() {
        const token = this.storage.getToken();
        if (token) {
            this.loggedIn = true;
            this.selectedCompany = this.storage.getUserCompany();
            this.isUserSysAdmin = this.storage.isUserSysAdmin();
            if (this.isUserSysAdmin) {
                this.getCompanies();
            }

            this.role = this.storage.getRole();
            this.username = this.storage.getUserUsername();
            this.termsAndConditions = this.storage.getUserTermsAndConditions();
            this.contract = this.storage.getUserContract();
            this.menu = this.menu.filter(item => !(item.hideContract != undefined && item.hideContract == true
                && ['Partinta Iva', 'Occasionale'].indexOf(this.contract) > -1)
            );

            this.admin = this.role == 'admin';
            this.principal = this.role == 'principal';
        } else {
            this.loggedIn = false;
        }
    }

    refreshCompanyData() {
        this.api.saveCompanyInfo();
    }

    private loadCompanyInfoFromStorage() {
        this.companyInfo = this.storage.getCompanyInfo();
        this.companyReports = this.storage.getCompanyReports();

        if (this.companyInfo) {
            this.menuImage = this.companyInfo.image_menu;
            this.headerImage = this.companyInfo.image_header;
            this.siteFavicon.href = this.companyInfo.image_favicon;

            this.companyInfo.description?.length > 0 && this.companyInfo.description != DEFAULT_TITLE ?
                this.titleService.setTitle(this.companyInfo.description + ' - ' + DEFAULT_TITLE)
                : this.titleService.setTitle(DEFAULT_TITLE);

            let reportList = [];
            this.companyReports?.forEach((companyReport: CompanyReportModel) => {
                // preapro la voce di menu per ogni report
                reportList.push({
                    'type': 'item',
                    'routerLink': companyReport.path,
                    'icon': companyReport.icon,
                    'title': companyReport.description,
                    'role': 'admin'
                });
                // aggiungo le rotte per i report
                this.addRoute(companyReport.path, companyReport.entityName);
            });

            if (reportList.length > 0) {
                this.menu.push({ 'type': 'dropdown', 'icon': 'report', 'title': 'Report', 'role': 'admin', 'items': reportList });
            }
        }
    }



    addRoute(_path: string, _source: string) {
        const newRoute: Route = {
            // se il primo carattere è / lo rimuove in quanto la rotto non può iniziare con /
            path: _path.startsWith('/') ? _path.substring(1) : _path,
            component: ReportComponent,
            canActivate: [RoleGuard],
            data: { roles: ['admin'], source: _source },
        };

        // Recupera le rotte attuali
        const existingRoutes = this.router.config;

        // Combina le rotte esistenti con le nuove
        const updatedRoutes = [newRoute, ...existingRoutes];
        // il comando sopra aggiunge le rotte nuove sempre in testa, quindi l'ordine è invertito
        // questo potrebbe eventualmente creare problemi in caso di precedenza delle rotte
        // qualora servisse, le righe di seguito fanno in modo di inserire la rotta immediatamente prima del wildcard **
        // // Trova l'indice della rotta '**'
        // const wildcardIndex = existingRoutes.findIndex(route => route.path === '**');
        // // Inserisce la nuova rotta prima della rotta '**'
        // const updatedRoutes = [
        //     ...existingRoutes.slice(0, wildcardIndex),
        //     newRoute,
        //     ...existingRoutes.slice(wildcardIndex)
        // ];

        // Aggiorna la configurazione delle rotte
        this.router.resetConfig(updatedRoutes);
    }

    ngOnInit() {
        if (!PRODUCTION) {
            // prevenie che il sito di test venga indicizzato
            this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getCompanies() {
        const sourceName = 'companies';
        const fields = ['id', 'description_short'];
        const filters = [];
        const sort = [{ field: 'description_short', direction: 'asc' }];
        const options = {};
        this.api.select(typeof {}, sourceName, fields, filters, sort, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.companies = data;
                }
            );
    }

    onCompanyChange(event) {
        this.storage.setUserCompany(event.value);
        window.location.reload();
    }

    checkScreenSize() {
        this.smallScreen = window.innerWidth < 768;
    }
}
