import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CompanyReportModel } from 'src/app/models/db.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-report-home',
  imports: [CommonModule, RouterModule, MatIcon],
  templateUrl: './report-home.component.html',
  styleUrl: './report-home.component.css'
})
export class ReportHomeComponent {
  reports: CompanyReportModel[] = [];
  
  constructor(
    private storage: StorageService
  ) {
    this.storage.companyReports$.subscribe(companyReports => {
      if (companyReports) {
        this.reports = companyReports;
      }
    });
  }
}
