<mat-sidenav-container class="menu-container" fullscreen>
    <mat-sidenav #sidenav
                [mode]="smallScreen ? 'over' : 'side'"
                [autoFocus]="false"
                [opened]="loggedIn && (!smallScreen || opened)"
                (opened)="events.push('open!')"
                (closed)="events.push('close!')"
                [disableClose]="!smallScreen"
                style="max-height: 100vh; position: fixed;">
        <span style="width: calc(100% - 60px); display: block; padding: 30px; text-align: center; text-align: -webkit-center;">
            <img [routerLink]="['/']" style="cursor:pointer; max-height: 75px; max-width:200px; vertical-align: middle;" src="{{menuImage}}" />
        </span>

        <div *ngIf="isUserSysAdmin" style="align-self: center;">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedCompany" (selectionChange)="onCompanyChange($event)">
                    <mat-option [value]="0">Tutte</mat-option>
                    <mat-option *ngFor="let company of companies" [value]="company['id']">
                        {{company['description_short']}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-nav-list role="navigation" *ngIf="loggedIn && termsAndConditions" style="width: 299px;">
            <ng-container *ngFor="let item of menu; let index = index">
                <ng-container
                    *ngIf="item.type=='dropdown' && (item.role=='user' || admin) && !(mobile && (item.title == 'Report' || item.title == 'Upload')) && (role != 'principal')">
                    <cdk-accordion>
                        <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button"
                            [attr.aria-expanded]="accordionItem.expanded"
                            [attr.aria-controls]="'accordion-body-' + index">
                            <div (click)="accordionItem.toggle()">
                                <mat-list-item class="dropdown-item-container">
                                    <div matListItemTitle>
                                        <span class="dropdown-item-title">{{item.title}}</span>
                                        <span class="dropdown-item-toggle">
                                            <mat-icon>{{accordionItem.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
                                        </span>
                                    </div>
                                </mat-list-item>
                            </div>
                            <div [style.display]="accordionItem.expanded ? '' : 'none'"
                                [attr.id]="'accordion-body-' + index">
                                <ng-container *ngFor="let subitem of item.items">
                                    <mat-list-item *ngIf="subitem.role=='user' || admin" routerLinkActive="active-link"
                                        [routerLink]="[subitem.routerLink]" [routerLinkActiveOptions]="{ exact: true }"
                                        class="sub-list-item">
                                        <mat-icon matListItemIcon>{{subitem.icon}}</mat-icon>
                                        <div matListItemTitle>{{subitem.title}}</div>
                                    </mat-list-item>
                                </ng-container>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </ng-container>
                <ng-container
                    *ngIf="item.type=='item' && (item.role=='user' || admin) && !(mobile && (item.title == 'Report' || item.title == 'Upload')) && (role != 'principal' || item.title == 'Presenze')">
                    <mat-list-item routerLinkActive="active-link" [routerLink]="[item.routerLink]" [routerLinkActiveOptions]="{ exact: true }">
                        <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
                        <div matListItemTitle>{{item.title}}</div>
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </mat-nav-list>
        <div class="sidenav-footer" *ngIf="!smallScreen">
            <button mat-button class="color_main" [routerLink]="['/profilo']">{{username}}</button>
            <button mat-button class="favorite-icon color_main" [routerLink]="['/logout']">
              <mat-icon>logout</mat-icon>
            </button>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar *ngIf="loggedIn && smallScreen">
            <button mat-icon-button aria-label="Menu toggle" (click)="sidenav.toggle()" *ngIf="termsAndConditions">
                <mat-icon class="color_main">menu</mat-icon>
            </button>
            <span [routerLink]="['/']" style="cursor:pointer; align-self: end;">
                <img style="height: 45px;" src="{{headerImage}}" />
            </span>
            <span class="mat-toolbar-spacer"></span>
            <button mat-button class="color_main" [routerLink]="['/profilo']">{{username}}</button>
            <button mat-icon-button class="favorite-icon color_main" [routerLink]="['/logout']">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>