<!-- Table card -->
<!-- div #tableCard class="mat-elevation-z8" class="table_card" -->
<div [class.tableLoading]="dataLoading">
    <mat-spinner class="loadingSpinner"></mat-spinner>
</div>
<!-- Table -->
<div #tableContainer class="table_container">
    <mat-table #matTable multiTemplateDataRows [dataSource]="dataSource" matSort [matSortDisabled]="!tableOptions.sorting"
        [class.table-responsive]="tableOptions.responsive" class="{{tableOptions.tableClass}}"
        (matSortChange)="tableSortHeaders($event)">

        <!-- Column Header cell -->
        <ng-container *ngFor="let col of tableVisibleFields; let index = index" matColumnDef="{{col.name}}">
            <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{
                    'd-none-mobile' : index > 10
                }">
                {{col.label}}
            </mat-header-cell>
            <mat-cell *matCellDef="let cell" [ngClass]="{
                    'numberTd'   : tableVisibleFieldsKV[col.name]['format']=='number',
                    'currencyTd' : tableVisibleFieldsKV[col.name]['format']=='currency',
                    'stringTd'   : tableVisibleFieldsKV[col.name]['format']=='string',
                    'datetimeTd' : tableVisibleFieldsKV[col.name]['format']=='datetime',
                    'dateTd'     : tableVisibleFieldsKV[col.name]['format']=='date',
                    'booleanTd'  : tableVisibleFieldsKV[col.name]['format']=='boolean',
                    'd-none-mobile' : index > 10
                }">
                <!--- 'd-none-mobile' : index > 5 || ( table.sourceName == 'assistances' && ['execution', 'start_time', 'end_time', 'fullname_student'].indexOf(col.name) == -1 ) --->
                
                <!--- <small class="d-inline-mobile" style="color: darkgrey">{{col.label}}:&nbsp;</small> --->
                <ng-container [ngSwitch]="tableVisibleFieldsKV[col.name]['format']">
                    <ng-container *ngSwitchCase="'number'">
                        {{cell[col.name]}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'currency'">
                        €&nbsp;{{cell[col.name]}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'string'">
                        {{cell[col.name]}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'datetime'">
                        {{cell[col.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">
                        {{cell[col.name] | date: getDateFormat()}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'time'">
                        {{cell[col.name]*1000 | date: 'HH:mm':'UTC'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'boolean'">
                        <mat-icon *ngIf="cell[col.name]==true || cell[col.name]==1" class="boolean_icon_true">check</mat-icon>
                        <mat-icon *ngIf="cell[col.name]==false || cell[col.name]==0" class="boolean_icon_false">minimize</mat-icon>
                    </ng-container>
                </ng-container>
            </mat-cell>
        </ng-container>

        <!-- Aciton Header cell -->
        <ng-container matColumnDef="actionColumn" *ngIf="tableOptions.rowActionMenu && !tableOptions.noInsert" stickyEnd>
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="table-action-button">
                <button mat-icon-button aria-label="Action button" [matMenuTriggerFor]="actionOptionsMenu"
                    [matMenuTriggerData]="{id: element.id, editable: element.editable}"
                    (click)="handleActionsClick($event)">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details_column">
            <td *matCellDef="let element" [attr.colspan]="tableVisibleFieldsList.length">
                <div #elementDetails class="element-details" [class.expanded]="element === expandedElement" [style.--details-width.px]="details_width">
                    <div class="element-details-content" *ngIf="element === expandedElement">
                        <button mat-button (click)="handleOpenDialog('update', element);" class="detail_edit_button"
                                *ngIf="tableOptions.rowActionEdit && ( element['editable'] || role=='admin' ) && role != 'principal'">
                            <mat-icon style="margin-right: 0px;">edit</mat-icon>
                        </button>

                        <ng-container *ngFor="let group of fieldsGroups">
                            <div class="group-wrapper" *ngIf="getGroupFields(group).length > 0">
                                <label *ngIf="group" class="group-title">{{group}}</label>
                                <ng-container *ngFor="let field of getGroupFields(group)">
                                    <span class="details_section_span">
                                        <small class="field_name">{{tableVisibleFieldsKV[field.name]['label']}}</small>
                                        <br />
                                        
                                        <ng-container [ngSwitch]="tableVisibleFieldsKV[field.name]['format']" class="filed_value">
                                            <ng-container *ngSwitchCase="'number'">
                                                {{expandedElement[field.name]}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'currency'">
                                                € {{expandedElement[field.name]}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'string'">
                                                <a *ngIf="tableVisibleFieldsKV[field.name]['linkText']?.length > 0 && role=='admin'"
                                                    [routerLink]="[tableVisibleFieldsKV[field.name]['linkText'], expandedElement[tableVisibleFieldsKV[field.name]['linkItemField']]]">
                                                    {{expandedElement[field.name]}}
                                                </a>
                                                <span *ngIf="!(tableVisibleFieldsKV[field.name]['linkText']?.length > 0) || role!='admin'" class="pre-text">
                                                    {{expandedElement[field.name]}}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'datetime'">
                                                {{expandedElement[field.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'date'">
                                                {{expandedElement[field.name] | date: getDateFormat()}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'time'">
                                                {{expandedElement[field.name]*1000 | date: 'HH:mm':'UTC'}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'boolean'">
                                                <mat-icon *ngIf="expandedElement[field.name]==true || expandedElement[field.name]==1" class="boolean_icon_true">check</mat-icon>
                                                <mat-icon
                                                    *ngIf="expandedElement[field.name]==false || expandedElement[field.name]==0" class="boolean_icon_false">minimize</mat-icon>
                                            </ng-container>
                                        </ng-container>
                                            
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- Popup shared component -->
                        <button mat-raised-button class="button-with-icon button-no-force-circle" [routerLink]="element.id" *ngIf="tableOptions.rowActionDetail && role != 'principal'">
                            Ulteriori informazioni <i class="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="empty_row">
            <td *matCellDef="let element" [attr.colspan]="tableVisibleFieldsList.length">
                <div></div>
            </td>
        </ng-container>

        <!-- Riga dei titoli, con ordinamento -->
        <mat-header-row mat-header-row
            *matHeaderRowDef="tableVisibleFieldsList; sticky: true"></mat-header-row>
        
        <mat-row mat-row *matRowDef="let row; columns: ['empty_row']" class="empty_row"></mat-row>
        
        <mat-row mat-row *matRowDef="let element; columns: tableVisibleFieldsList"
            (click)="( tableOptions.rowClick=='edit' && ( element.editable==1 || role=='admin' ) && role != 'principal') ? handleOpenDialog('update', element) : 
                        tableOptions.rowClick=='info' ? expandedElement = expandedElement === element ? null : element : null"
            [routerLink]="( tableOptions.rowClick=='detail' && role != 'principal' ) ? element['id'] : null"
            [class.rowClickable]="tableOptions.rowClick!=null" class="row_main"
            [class.expanded]="element === expandedElement"></mat-row>
        
        <!-- Expandable detail row, colspan of all columns tableVisibleFieldsList.length, hidden by default -->
        <mat-row mat-row *matRowDef="let row; columns: ['details_column']" class="row_details"></mat-row>

        <mat-row mat-row *matRowDef="let row; columns: ['empty_row']" class="empty_row"></mat-row>
    </mat-table>
</div>

<!-- Paginazione -->
<mat-paginator *ngIf="tableOptions.pagination" [length]="count" [pageSize]=limit
    [pageSizeOptions]=tableOptions.pageSizeOptions aria-label="Paginazione"
    (page)="paginatorChange($event)">
</mat-paginator>
<!-- /div -->

<!-- Option menu template -->
<mat-menu #actionOptionsMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-id="id" let-editable="editable" *ngIf="tableOptions.rowActionMenu">
        <button mat-menu-item [routerLink]="id" *ngIf="tableOptions.rowActionDetail && role != 'principal'">
            <mat-icon>open_in_new</mat-icon>
            <span>Apri</span>
        </button>
        <button mat-menu-item (click)="handleOpenDialog('update', getDataById(id))"
            *ngIf="tableOptions.rowActionEdit && ( editable==1 || role=='admin' ) && role != 'principal'">
            <mat-icon>edit</mat-icon>
            <span>Modifica</span>
        </button>
        <!-- se non sono presenti azioni di apertura dettaglio o modifica -->
        <span
            *ngIf="!tableOptions.rowActionDetail && !(tableOptions.rowActionEdit && ( editable==1 || role=='admin' )) || role == 'principal'">
            <small style="padding: 4px 12px">Nessuna azione</small>
        </span>
    </ng-template>
</mat-menu>