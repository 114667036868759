import { Component } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { Subject, takeUntil } from 'rxjs';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { CompanyModel } from 'src/app/models/db.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    imports: [
      RouterLink,
      CommonModule
    ]
})
export class HomeComponent {
  contract: string;
  homeTitle: string;
  homeImage: string;
  companyInfo: CompanyModel;
  noAbsencesContracts = ['Partinta Iva', 'Occasionale'];
  
  constructor(
    private storage: StorageService
  ) {
    this.storage.companyInfo$.subscribe(companyInfo => {
      if (companyInfo) {
        this.companyInfo = companyInfo;
        this.homeTitle = companyInfo.description;
        this.homeImage = companyInfo.image_home;
      }
    });
    // this.companyInfo = this.storage.getCompanyInfo();
    // this.homeTitle = this.companyInfo.description;
    // this.homeImage = this.companyInfo.image_home;
    this.contract = this.storage.getUserContract();
  }

  ngOnInit() {
  }

  contractHasAbsences(contract: string) {
    return this.noAbsencesContracts.indexOf(contract) == -1
  }
}
