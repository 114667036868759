<div class="tc_body" style="padding:16px;">
    <p class="tc_banner">
        Per procedere, è necessario leggere e accettare Termini, Condizioni e Privacy Policy.
    </p>

    <app-terms-and-conditions-details></app-terms-and-conditions-details>

    <div class="tc_buttons">
        <button mat-raised-button (click)="accept()">Accetta</button>
    </div>
</div>