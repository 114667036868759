import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FiltersModel } from '../models/entity.model';
import { environment } from '../../environment/environment';
import { Subject, takeUntil } from 'rxjs';
import { CompanyModel, CompanyReportModel } from 'src/app/models/db.model';
import { Router } from '@angular/router';
import { NotifierService } from './notifier.service';
import { StorageService } from './storage.service';

// server test
// const API_URL = 'HTTPS://gass.3cengineering.it:8000/api';
// server prod
//const API_URL = 'https://myitinera.coopitinera.it:8000/api';

// local con proxy
// const API_URL = '/api';

// url contenuta nel file environment
const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({
    'accept': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private destroy$ = new Subject<void>();
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private notifier: NotifierService,
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  select(dataModel: any, table: string, fields: string[], filters: FiltersModel[], sort: any[], options: {}) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters,
      "sort": sort,
      "options": options ? options : {}
    };

    return this.http.post<typeof dataModel>(API_URL + '/select/', data, httpOptions);
  }

  insert(dataModel: any, table: string, fields: {}) {
    const data = {
      "table": table,
      "fields": fields
    };

    return this.http.post<typeof dataModel>(API_URL + '/insert/', data, httpOptions);
  }

  update(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/update/', data, httpOptions);
  }

  delete(dataModel: any, table: string, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/delete/', data, httpOptions);
  }

  upsert(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/upsert/', data, httpOptions);
  }
  
  saveCompanyInfo(): Promise<void>{
    // console.log('Refreshing company data...');

    return new Promise((resolve) => {
      this.select(null, 'companies', ['description', 'description_short', 'image_menu', 'image_header', 'image_home', 'image_favicon'], [], [], {})
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            const company: CompanyModel = {
              id: data[0].id,
              description: data[0].description,
              description_short: data[0].description_short,
              image_menu: data[0].image_menu,
              image_header: data[0].image_header,
              image_home: data[0].image_home,
              image_favicon: data[0].image_favicon
            }
            this.storage.setCompanyInfo(company)
              .then(() => this.saveCompanyReports().then(() => {
                resolve();

                // this.router.navigate(['/']);
                // redirect to / if incoming path was login
                const path = this.router.url;
                if (path.startsWith('/login')) {
                  this.router.navigate(['/']);
                }
            }));


          },
          error: err => {
            console.log(err);
            this.notifier.showWarning('Attenzione', 'Non è stato possibile recuperare le informazioni della cooperativa.');
            this.router.navigate(['/']);
          }
        });
      });
  }

  saveCompanyReports(): Promise<void> {
    // console.log('Loading company reports...');
    return new Promise((resolve) => {
      this.select(null, 'companies_reports', ['description_report', 'path_report', 'entity_name_report', 'icon_report'], [], [], {})
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            const companyReports: CompanyReportModel[] = [];
            data.forEach(element => {
              const companyReport: CompanyReportModel = {
                description: element.description_report,
                path: element.path_report,
                entityName: element.entity_name_report,
                icon: element.icon_report,
              }
              companyReports.push(companyReport);
            });

            this.storage.setCompanyReports(companyReports).then(() => resolve());

            // this.router.navigate(['/']);
            // redirect to / if incoming path was login
            // const path = this.router.url;
            // if (path.startsWith('/login')) {
            //   this.router.navigate(['/']);
            // }
          },
          error: err => {
            console.log(err);
            this.notifier.showWarning('Attenzione', 'Non è stato possibile recuperare i report della cooperativa.');
            this.router.navigate(['/']);
          }
        });
      });
  }

}
