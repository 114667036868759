import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EntityModel, FieldsModel, FiltersModel } from 'src/app/models/entity.model';
import { Subscription } from 'rxjs';
import CodiceFiscale from 'codice-fiscale-js';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { DateFilterFn, MatDatepickerModule } from '@angular/material/datepicker';
import { NotifierService } from 'src/app/services/notifier.service';
import { ApiService } from 'src/app/services/api.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { StorageService } from 'src/app/services/storage.service';

// formato data visualizzato nel filtro laterale
export const MY_FORMATS = {
  parse: {
    // dateInput: 'LL',
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
    selector: 'app-form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.css'],
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatCardModule,
        MatProgressBarModule,
        CommonModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatIconModule
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class FormModalComponent {
  Validators = Validators;
  isAdmin: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FormModalComponent>,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private api: ApiService,
    private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: {
      model: EntityModel;
      formMode: 'insert' | 'update';
      selObj: any;
    },
  ) {
    // recupera e controlla il ruolo dell'utente
    const token = this.storage.getToken();
    if (token) {
      if (this.storage.getRole() == 'admin') {
        this.isAdmin = true;
      }
    }
  }

  formMode = this.data.formMode;
  loading: boolean;
  formIsChanged: boolean;
  isEditMode: boolean;
  formChangeSubscription: Subscription;
  insertObjForm: FormGroup = this.formBuilder.group({});
  updateObjForm: FormGroup = this.formBuilder.group({});
  dynamicForm: FormGroup = this.formBuilder.group({});
  dynamicData = [];
  filteredDynamicData = [];
  requiredContionsNames: string[];
  extRequiredContionsNames: FieldsModel[];
  extRequiredContionsData = [];
  formSelectList: string[];
  allFieldsKV: FieldsModel[] = [];
  formFields: FieldsModel[];

  ngOnInit() {
    this.requiredContionsNames = this.data.model.getRequiredConditionsNames();
    this.extRequiredContionsNames = this.data.model.getExtRequiredConditionsFields();
    this.allFieldsKV = this.data.model.getAllFieldsKV();
    this.formFields = this.data.model.getFormFields();

    // Recupera la lista dei campi che hanno una form select
    this.formSelectList = this.data.model.getFormSelectList();
    this.formSelectList.forEach(fieldName => {
      this.dynamicData[fieldName] = {
        "form": this.data.model.getFormSelectByFieldName(fieldName),
        "data": []
      };
      this.filteredDynamicData[fieldName] = {
        "data": []
      };
    });

    // Aggiunge i campi all form (sia inserimento che modifica)
    this.data.model.getFormFields().forEach(field => {
      if (field.form != null) {
        this.formAddControl(
          field.crud,
          field.name,
          field.form.defaultValue,
          field.form.isRequired,
          field.form.minLength,
          field.form.maxLength,
          field.form.pattern,
          field.form.disabled ? true : false,
          field.form.select?.filter ? true : false
        );
      }
    });

    // scarica eventuali dati per le form select
    this.formSelectList.forEach(fieldName => {
      let options: {} = {};
      if (this.dynamicData[fieldName].form.select.distinct) {
        options = {
          'distinct': true
        };
      }

      let selectList = [];
      // id della select
      selectList.push(this.dynamicData[fieldName].form.select.id);
      // label della select
      selectList.push(this.dynamicData[fieldName].form.select.label);
      // eventuale chiave per un filtro dipendente da altra select
      if (this.dynamicData[fieldName].form.select.upSelectFilter) {
        selectList.push(this.dynamicData[fieldName].form.select.upSelectFilter.filterKey);
      }

      this.api.select(
        typeof {},
        this.dynamicData[fieldName].form.select.sourceName,
        selectList,
        this.dynamicData[fieldName].form.select.filters,
        this.dynamicData[fieldName].form.select.sort != undefined ? this.dynamicData[fieldName].form.select.sort : [],
        options
      ).subscribe(data => {
        this.dynamicData[fieldName]['data'] = data;
        if (this.dynamicData[fieldName].form.select.upSelectFilter == undefined) {
          this.filteredDynamicData[fieldName]['data'] = data;
        }
        // aggiunte a ogni entità caricata per far fronte a un caricamento asincrono introdotto con il componente shared
        if (this.formMode == 'insert') this.newObj();
        if (this.formMode == 'update') this.selectObj(this.data.selObj);

        if (this.data.selObj != null && this.data.selObj != undefined) {
          // resetta le obbligatorietà condizionate
          this.updateConditionalRequired();
          this.updateExtConditionalRequired();
          // filtra le select in base ai valori selezionati
          this.prefilterSelect(this.data.selObj);
        }
      });
    });

    // scarica eventuali dati per i campi con extRequiredConditions
    this.extRequiredContionsNames.forEach(field => {
      let options: {} = {};
      if (field.form.select?.distinct) {
        options = {
          'distinct': true
        };
      }
      this.extRequiredContionsData[field.name] = [];
      
      const selectList: string[] = [ field.form.extConditionalRequired.field, field.form.extConditionalRequired.externalId ];
      let filters: FiltersModel[] = field.form.extConditionalRequired.filters;

      this.api.select( typeof {}, field.form.extConditionalRequired.sourceName, selectList, filters, [], options ).subscribe(data => {
        this.extRequiredContionsData[field.name] = {
          externalId: field.form.extConditionalRequired.externalId,
          localId: field.form.extConditionalRequired.localId,
          field: field.form.extConditionalRequired.field,
          data: data
        }
        this.updateExtConditionalRequired();
      });
    });

    if (this.formMode == 'insert') this.newObj();
    if (this.formMode == 'update') this.selectObj(this.data.selObj);
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) this.formChangeSubscription.unsubscribe();
  }

  formAddControl(crud: FieldsModel["crud"], name: string, value: string | number, isRequired: boolean,
    minLength: number, maxLength: number, pattern: string, disabled: boolean, filter: boolean) {
    // Aggiunge il campo alla form (sia inserimento che modifica)
    let validatorsList = [];
    if (isRequired) validatorsList.push(Validators.required);
    if (minLength) validatorsList.push(Validators.minLength(minLength));
    if (maxLength) validatorsList.push(Validators.maxLength(maxLength));
    if (pattern) validatorsList.push(Validators.pattern(pattern));
    // Se il campo prevede insert, aggiungo il controllo alla form di insert
    if (crud.indexOf('insert') !== -1) {
      // this.insertObjForm.addControl(name, this.formBuilder.control(value, validatorsList));
      this.insertObjForm.addControl(name, this.formBuilder.control({ value: value, disabled: disabled }, validatorsList));
      if (filter) {
        this.insertObjForm.addControl('selectFilter_' + name, this.formBuilder.control(null));
      }
    }
    // Se il campo prevede update, aggiungo il controllo alla form di insert
    if (crud.indexOf('update') !== -1) {
      // this.updateObjForm.addControl(name, this.formBuilder.control(value, validatorsList));
      this.updateObjForm.addControl(name, this.formBuilder.control({ value: value, disabled: disabled }, validatorsList));
      if (filter) {
        this.updateObjForm.addControl('selectFilter_' + name, this.formBuilder.control(null));
      }
    }
  }

  get f() {
    return this.dynamicForm.controls;
  }

  customValidation(field: FieldsModel) {
    // controllo di validità per i tipi custom
    if (field.form?.customValidation == 'codiceFiscale') {
      const fieldName: string = field.form.name;
      const formControl = this.f[fieldName];
      if (formControl.errors == null || formControl.errors['codiceFiscale']) {
        try {
          const fiscalCode = new CodiceFiscale(formControl.value);
          // vengono svuotati gli errori del campo assumendo che se siamo qui, l'unico errore possibile è quello "codiceFiscale"
          formControl.setErrors(null);
        } catch {
          // la chiamata "new CodiceFiscale()" genera errore in caso di CF non valido
          formControl.setErrors({ 'codiceFiscale': true });
        }
      }
    }
  }

  newObj() {
    // prepara l'inserimento di un nuovo record
    this.isEditMode = false;
    let selectChanged = [];

    // controlla tutti i campi della form di tipo select
    // se ce ne sono alcuni obbligatori con una sola occorrenza, li seleziona
    this.data.model.getFormFields().forEach(field => {
      if (field.form != null) {
        if (field.form.type == 'select') {
          // if (this.dynamicData[field.name]['data'].length == 1
          if (this.filteredDynamicData[field.name]['data'].length == 1
            && this.insertObjForm.controls[field.name] != undefined
            && field.form.isRequired) {
            const oneValue = this.filteredDynamicData[field.name]['data'][0][field.form?.select?.id];
            // Aggiorno il valore della select con l'unico valore disponibile
            this.insertObjForm.controls[field.name]
              // .setValue(this.dynamicData[field.name]['data'][0]['id']);
              .setValue(oneValue);
            // Marco il campo come Dirty, altrimenti non viene inviato all'api
            this.insertObjForm.controls[field.name].markAsDirty();
            // Tengo traccia di tutti i campi select dove è stato prevalorizzato l'unico valore possibile
            selectChanged.push(field.name);
          }
        }
      }
    });

    // popola la form generica (usata dall'html) con la form di insert
    this.dynamicForm = this.insertObjForm;
    this.formChangeSubscriber(this.dynamicForm);
    // resetta le obbligatorietà condizionate
    this.updateConditionalRequired();
    this.updateExtConditionalRequired();
    // ciclo i campi select dove è stato prevalorizzato l'unico valore possibile per controllare se avevano campi in downSelectFilter
    selectChanged.forEach(field => {
      this.selectChange(field, true);
    });
  }

  selectObj(inputElement: typeof this.data.model.model) {
    // var selElement = JSON.parse(JSON.stringify(inputElement));
    var selElement = structuredClone(inputElement);
    // prepara la modifica del record selezionato
    this.isEditMode = true;
    this.formMode = 'update';
    // popola la form generica (usata dall'html) con la form di update
    this.dynamicForm = this.updateObjForm;
    // modifica il formato dei campi ora da secondi (come arriva dal DB) a HH:mm (come serve all'HTML)
    Object.keys(selElement)
      .filter(f => this.data.model.getFieldFormFormat(f) == 'time')
      .forEach(f => {
        const formattedTime = moment.utc(selElement[f] * 1000).format('HH:mm');
        selElement[f] = formattedTime;
      });
    this.dynamicForm.patchValue(selElement);
    this.formChangeSubscriber(this.dynamicForm);
    // resetta le obbligatorietà condizionate
    this.updateConditionalRequired();
    this.updateExtConditionalRequired();
    // filtra le select in base ai valori selezionati
    this.prefilterSelect(selElement);
  }

  formChangeSubscriber(form: FormGroup) {
    // aggiunge un listener sulla modifica della form
    // viene eseguito solo una volta, per evitare di creare
    // un nuovo subscribe ogni volta che viene invocato il metodo

    // cancella l'eventuale precedente subscription
    if (this.formChangeSubscription) this.formChangeSubscription.unsubscribe();
    // crea una subscription in ascolto del cambiamento dei valori della form
    this.formChangeSubscription = form.valueChanges.subscribe(value => {
      this.formIsChanged = this.formChanged(form);
    });
  }

  formChanged(form: FormGroup): boolean {
    // verifica se la form in input ha valore dirty (cambiati)
    let output = false;
    Object.keys(form.controls).forEach(f => {
      if (form.controls[f].dirty)
        output = true;
    });
    return output;
  }

  updateConditionalRequired() {
    Object.keys(this.requiredContionsNames).forEach(name => {
      this.checkConditionalRequired(name);
    });
  }

  checkConditionalRequired(name: string) {
    const conditionedList = this.requiredContionsNames[name];
    if (conditionedList != undefined && this.f[name] != undefined) {
      if (this.f[name].value) {
        // deve rendere obbligatori i campi associati
        conditionedList.forEach(f => {
          if (!this.dynamicForm.get(f).hasValidator(Validators.required)) {
            this.dynamicForm.get(f).addValidators(Validators.required);
            this.dynamicForm.get(f).updateValueAndValidity();
          }
        });
      } else {
        // deve rendere non obbligatori i campi associati
        conditionedList.forEach(f => {
          if (this.dynamicForm.get(f).hasValidator(Validators.required)) {
            this.dynamicForm.get(f).removeValidators(Validators.required);
            this.dynamicForm.get(f).updateValueAndValidity();
          }
        });
      }
    }
  }

  updateExtConditionalRequired() {
    Object.keys(this.extRequiredContionsData).forEach(name => {
      this.checkExtConditionalRequired(name);
    });
  }

  checkExtConditionalRequired(field: string) {
    const conditioned = this.extRequiredContionsData[field];

    if (conditioned != undefined && this.f[field] != undefined && this.f[conditioned.localId]) {
      const isRequired = conditioned.data
        .filter(d => d[conditioned.externalId] == this.f[conditioned.localId].value)
        .map(d => d[conditioned.field])[0];

      if (isRequired) {
        // deve rendere obbligatorio il campo associato
        if (!this.dynamicForm.get(field).hasValidator(Validators.required)) {
          this.dynamicForm.get(field).addValidators(Validators.required);
          this.dynamicForm.get(field).updateValueAndValidity();
        }
      } else {
        // deve rendere non obbligatorio il campo associato
        if (this.dynamicForm.get(field).hasValidator(Validators.required)) {
          this.dynamicForm.get(field).removeValidators(Validators.required);
          this.dynamicForm.get(field).updateValueAndValidity();
        }
      }
    }
  }

  prefilterSelect(selElement) {
    // per ogni campo select che ha un downSelectFilter, prepopola i campi del campo in "downline"
    // invocando il selectChange sul select in "upline"
    Object.keys(selElement)
      .forEach(f => {
        const upField = this.allFieldsKV[f]?.form?.select?.upSelectFilter?.upField;
        if (upField != undefined)
          this.selectChange(upField, null);
      });
  }

  selectChange(field: string, e: any) {
    // il campo in questione ha un filtro dipendente da un'altra select
    const hasUpSelectFilter = this.allFieldsKV[field].form.select?.upSelectFilter != undefined;
    // il campo in questione è indicato come filtro di un altro campo select
    const downSelectFilters = this.formFields.slice().filter(f => f.form.select?.upSelectFilter?.upField == field);
    const hasDownSelectFilter = downSelectFilters.length > 0;

    if (hasDownSelectFilter) {
      downSelectFilters.forEach(f => {
        // valore del campo select modificato
        const fieldValue = this.f[field].value;
        // filtra i dati del campo select dipendente da quello in questione
        this.filteredDynamicData[f.form.name]['data'] = this.dynamicData[f.form.name]['data']
          .slice()
          .filter(c => c[f.form.select.upSelectFilter.filterKey] == fieldValue);

        const valuesLength = this.filteredDynamicData[f.form.name]['data'].length;
        const controlExists = this.insertObjForm.controls[f.form.name] != undefined;
        const currField = this.formFields.slice().filter(ff => ff.form.name == f.form.name)[0];
        // oneDownValue indica se il campo filtrato ha un solo valore e verrà quindi preselezionato
        const oneDownValue = valuesLength == 1 && controlExists && currField.form.isRequired;
        if (oneDownValue && e != null) {
          const oneValue = this.filteredDynamicData[f.form.name]['data'][0][currField.form?.select?.id];
          // Aggiorno il valore della select con l'unico valore disponibile
          this.insertObjForm.controls[f.form.name].setValue(oneValue);
          // Marco il campo come Dirty, altrimenti non viene inviato all'api
          this.insertObjForm.controls[f.form.name].markAsDirty();
        }

        // l'evento di modifica è passato dal cambio valore della select "up"
        // se non è passato al metodo, vuol dire che il metodo è chiamato dall'input filter nella select "down"
        if (e) {
          // rimuove eventuale valore selezionato al campo dipendente e a ulteriori dipendenti a cascata
          this.clearDownSelectFilter(f.form.name);
        }

        // dopo aver rimosso eventuali altri valori in cascata già selezionati
        // visto che il campo filtrato ha un solo valore che è stato preselezionato
        // invoca lo stesso metodo sul campo preselezionato
        if (oneDownValue && e != null) {
          this.selectChange(f.form.name, true);
        }
      });
    }
    this.updateExtConditionalRequired();
  }

  selectFilter(field: string, e: any) {
    const filter = e?.target?.value?.toLowerCase();
    // il campo in questione ha un filtro dipendente da un'altra select
    const upSelectFieldName = this.allFieldsKV[field].form.select?.upSelectFilter?.upField;
    const hasUpSelectFilter = upSelectFieldName != undefined;
    // il campo in questione è indicato come filtro di un altro campo select
    const hasDownSelectFilter = this.formFields.slice().filter(f => f.form.select?.upSelectFilter?.upField == field).length > 0;
    let preFilteredData = [];
    // filtra i valori del campo in questione per il filtro impostato nell'input
    if (hasUpSelectFilter) {
      // prima di filtrare i valori con il pattern di ricerca li pre-filtra con quelli della select padre
      // questo metodo filtra la variabile filteredDynamicData usata successivamente
      this.selectChange(upSelectFieldName, null);
      // in questo caso il set di dati è pre-filtrato da selectChange
      preFilteredData = this.filteredDynamicData[field]['data'].slice();
    } else {
      // in questo caso il set di dati non è pre-filtrato, quindi si recupera il set originale
      preFilteredData = this.dynamicData[field]['data'].slice();
    }
    if (filter != '' && filter != undefined) {
      // this.filteredDynamicData[field]['data'] = this.dynamicData[field]['data']
      this.filteredDynamicData[field]['data'] = preFilteredData
        .filter(f => f[this.dynamicData[field].form.select.label].toLowerCase().includes(filter));
    } else {
      this.filteredDynamicData[field]['data'] = preFilteredData;
    }
  }

  clearDownSelectFilter(field: string) {
    const selectedValue = this.f[field].value;
    this.selectFilter(field, null);
    const possibleValues = this.filteredDynamicData[field]['data']
      .slice()
      .map(f => f[this.data.model.getFieldByName(field).form?.select?.id]);
    // se il valore precente del campo non è più presente tra i valori possibili, viene sbiancato
    if (possibleValues.indexOf(selectedValue) == -1) {
      this.f[field].setValue(null);
      this.f[field].markAsPristine();
    }

    // se il campo in questione ha il filtro, viene pulito anche questo
    if (this.f['selectFilter_' + field] != undefined) {
      this.f['selectFilter_' + field].setValue(null);
      this.f['selectFilter_' + field].markAsPristine();
    }

    const downSelectFilters = this.formFields.slice().filter(f => f.form.select?.upSelectFilter?.upField == field);
    const hasDownSelectFilter = downSelectFilters.length > 0;
    if (hasDownSelectFilter) {
      downSelectFilters.forEach(f => {
        this.clearDownSelectFilter(f.form.name);
      });
    }
  }

  onSubmit() {
    // invocato al submit della form (sia inserimento che modifica)

    // ferma l'esecuzione se la form non è valida
    // if (this.dynamicForm.invalid) {
    //   this.notifier.showWarning('Attenzione', 'Form non valida');
    //   return;
    // }

    // ferma l'esecuzione se la form ha campi modificati e non validi
    // la sottomissione non viene fermata in caso di update se i campi non validi non sono stati toccati
    const _invalidFields = Object.keys(this.dynamicForm.controls)
      .filter(
        (c) =>
          this.dynamicForm.controls[c].invalid &&
          (this.dynamicForm.controls[c].dirty || this.formMode == 'insert')
      )
      .filter((f) => this.data.model.getFieldByName(f) != undefined)
      .map((f) => this.data.model.getFieldByName(f).label)
      .join(', ');
    if (_invalidFields.length > 0) {
      this.notifier.showWarning(
        'Attenzione',
        'Form non valida.\nControllare: ' + _invalidFields
      );
      return;
    }

    // invia i dati di insert o update
    this.loading = true;
    this.upsertObj(this.formMode).subscribe({
      next: (response) => {
        this.dialogRef.close({ result: 'ok' });
        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
      },
      error: (err) => {
        this.loading = false;
        if(err.status != 444) {
          // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
          console.log(err);
          this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persistee');
        }
      }
    });
  }

  upsertObj(type: 'insert' | 'update') {
    // invia all'api la chiamata per l'insert
    const fieldsObj = {};
    const form = this.dynamicForm;
    // Prepara l'elenco dei campi da inviare all'api
    this.data.model.getFields().forEach(field => {
      if (field.form != null
        // controlla se il campo è compatibile con la form (insert o update)
        && field.crud.indexOf(type) !== -1
        // verifica che il campo abbia un valore
        && form.value[field.form.name] != null
        // verifica che il campo non sia l'id (in caso di update, va passato come filtro)
        && field.form.name != 'id'
        // verifica che il campo non sia un input selectFilter (non vanno passati)
        && !field.form.name.startsWith('selectFilter_')
        // solo i valori realmente cambiati
        && form.controls[field.form.name].dirty) {
        if (field.form.type == 'date') {
          // solo in caso di data formatto il valore
          fieldsObj[field.form.name] = moment(form.value[field.form.name]).format('YYYY-MM-DD');
        } else if (field.form.type == 'boolean') {
          let value: (0 | 1) = null;
          if (form.value[field.form.name] == true) value = 1;
          if (form.value[field.form.name] == false) value = 0;
          // il valore del campo è castato a 0/1 nel caso in cui erano true/false
          fieldsObj[field.form.name] = value != null ? value : form.value[field.form.name];
        } else {
          fieldsObj[field.form.name] = form.value[field.form.name];
        }
      }
    });

    if (type == 'insert') {
      return this.api.insert(typeof this.data.model.model, this.data.model.sourceName, fieldsObj);
    }

    if (type == 'update') {
      // preparo le condizioni di filtro dell'oggetto
      const updateFlter: FiltersModel[] = [{
        'field': 'id',
        'value': this.updateObjForm.value['id'],
        'operator': '='
      }];
      return this.api.update(typeof this.data.model.model, this.data.model.sourceName, fieldsObj, updateFlter);
    }

    return null;
  }

  cancel(): void {
    this.dialogRef.close({ event: 'cancel' });
  }

  dateFilter: DateFilterFn<Date> = (date: Date | null) => {
    const d = moment(date)
    const today = moment()

    if(this.isAdmin){
      return true;
    }

    // giorni da nascondere
    if (
      // il giorno è domenica
      d?.day() === 0
      // il giorno è successivo a oggi
      || d.isAfter(today)
      // il giorno è precedente alla settimana corrente 
      || (d?.isBefore(today, 'isoWeek'))
    )
      return false;
    return true;
  }

  checkTime() {
    const stTime = moment(this.f['start_time'].value, 'hh:mm');
    const endTime = moment(this.f['end_time'].value, 'hh:mm');

    const minStTime = moment('8:00', 'hh:mm');
    const minEndTime = moment('8:45', 'hh:mm');
    const maxStTime = moment('18:15', 'hh:mm');
    const maxEndTime = moment('19:00', 'hh:mm');

    if (!stTime.isValid()){
      this.f['start_time'].setErrors({ 'required': true });
      return true;
    } else {
      this.f['start_time'].setErrors(null);
    }

    // l'orario di inizio non è compreso tra min e max
    if (stTime.isBefore(minStTime) || stTime.isAfter(maxStTime)) {
      this.f['start_time'].setErrors({ 'startEnd': true });
      return true;
    } else {
      this.f['start_time'].setErrors(null);
    }
    // l'orario di fine non è compreso tra min e max
    if (endTime.isBefore(minEndTime) || endTime.isAfter(maxEndTime)) {
      this.f['end_time'].setErrors({ 'startEnd': true });
      return true;
    } else {
      this.f['end_time'].setErrors(null);
    }
    if (stTime.isValid() && endTime.isValid()) {
      const diff = moment.duration(endTime.diff(stTime))
      // 2700000  45 minuti
      // 36000000 10 ore
      if (diff.asMilliseconds() < 2700000 || diff.asMilliseconds() > 36000000) {
        // durata troppo corta o troppo lunga, aggiungo errore a entrambi i campi
        this.f['start_time'].setErrors({ 'duration': true });
        this.f['end_time'].setErrors({ 'duration': true });
        return true;
      } else {
        // non sono popolati entrambi i valori, quindi tolgo eventuali errori
        this.f['start_time'].setErrors(null);
        this.f['end_time'].setErrors(null);
      }
    }
    return true;
  }
}
